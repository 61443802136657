body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#meetingSDKElement {
  top: 82px !important;
}

html, body {
  width: 100% !important;
  min-width: 100% !important;
}

@media screen and (max-width:1023px) {}

@media screen and (max-width:899px) {

  .ReactModal__Content {
    width: 95% !important;
  }

  .zm-modal {
    width: 100% !important;
    margin: 0 auto !important;
  }

  .join-dialog__tabs .zmu-tabs__tab-container .zmu-tabs__tabs-list .zmu-tabs__tab-bar {
    flex-basis: 40% !important;
  }

  .join-dialog__tabs .zmu-tabs__tab-container .zmu-tabs__tabs-list .zmu-tabs__tab-bar .tab-bar-node__text {
    font-size: 13px !important;
  }

  .zmu-tabs__tabpanel#phone-tab {
    width: 90% !important;
    margin: 0 auto;
    height: 250px;
    overflow: auto;
  }

  .join-audio-by-phone__steps {
    width: 100% !important;
    padding: 0 !important;
  }

  .invite-window__header {
    font-size: 16px !important;
  }

  .invite-email__content {
    margin: 20px auto !important;

  }

  #window-wrapper .common-window-2__container {
    height: fit-content !important;
  }

}

@media screen and (max-width:599px) {

  .meeting-info-icon__meeting-paper {
    width: calc(100vw - 40px) !important;
  }

  .third-part-app-info__panel {
    width: calc(100vw - 100px) !important;
  }

  .reaction-picker--in-more {
    left: -50% !important;
  }

  .ReactModal__Content .common-window {
    width: 100% !important;
  }

  .ReactModal__Content--after-open {
    width: 80% !important;
    /* height: 50vh !important; */
  }

  .ReactModal__Overlay--after-open {
    /* height: 50vh !important; */
  }

  .chat-scrollbar, .participant-scrollbar,
  .ReactVirtualized__Grid.chat-virtualized-list {
    height: 200px !important;
    overflow: auto;
  }

  #participant-window {
    height: 300px !important;
  }

  #dialog-invite {
    width: 100% !important;
  }

  .zmu-paper .common-window-2__footer .invite-footer__button-group {
    display: block !important;
  }

  .zmu-paper .common-window-2__footer .invite-footer__button-group .invite-footer__button-group-right,
  .zmu-paper .common-window-2__footer .invite-footer__button-group .invite-footer__left-btns {
    justify-content: center !important;
  }

  .settings-dialog {
    width: 100% !important;
    left: 0 !important;
  }

  .audio-option-menu__pop-menu {
    width: 70vw !important;
    left: 0 !important;

  }

  .custom-dropdown-menu.dropdown-menu>li a {
    white-space: break-spaces !important;
  }

  .ReactVirtualized__List.chat-virtualized-list {
    width: 100% !important;
  }

  .join-audio-by-phone__toll-country,
  .join-audio-by-phone__toll-number {
    font-size: 9px !important;
  }

  .join-audio-by-phone__country-select {
    flex-basis: 50% !important;
  }

  .join-audio-by-phone__toll-number-list-content {
    margin-left: 4px !important;
    padding-right: 4px !important;
  }

  .join-audio-by-phone__steps {
    width: 95% !important;
    padding: 0 !important;
  }

  .zmu-tabs__tabpanel#phone-tab {
    height: 250px;
    overflow: auto;
  }
}

@media screen and (orientation: landscape) {
  .zmu-paper.meeting-info-icon__meeting-paper {
    height: 50vh !important;
    overflow: auto !important;
    min-height: unset !important;
  }

  .third-part-app-info__panel {
    height: 50vh !important;
    overflow: auto !important;
    min-height: unset !important;
  }

}


.start_div {
  display: flex;
  z-index: 9;
  position: absolute;
}